import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getRoles = (page,limit,search,status) => {
  //console.log(getCountry);
	return axios.get(`${apiUrl}/v1/roles?page=${page}&limit=${limit}&role_name=${search}&active_status=${status}`, {})
		.then(response => {
  //console.log(response);
	    return response.data
	  })
}

const createRoles = (role_name,active_status) => {
	return axios.post(`${apiUrl}/v1/roles`, {
    role_name: role_name,
        active_status : active_status,
   
})
}



const updateRoles = (master_role_id,role_name,active_status) => {
//console.log('------------->',id,hobbiesname,active_status)
	return axios.patch(`${apiUrl}/v1/roles/${master_role_id}`, {
    role_name: role_name,
    active_status: active_status,
  })
}

const deleteRoles = (master_role_id) => {
	return axios.delete(`${apiUrl}/v1/roles/${master_role_id}`)
}

const createLogs =(payload) =>{
  return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}

export const rolesService = {
  getRoles,
  createRoles,
  createLogs,
  updateRoles,
  deleteRoles,
}