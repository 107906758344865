import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getCity = (page,limit,search,status) => {
	return axios.get(`${apiUrl}/v1/city?page=${page}&limit=${limit}&city_name=${search}&active_status=${status}`, {})
		.then(response => {
	    return response.data
	  })
}
const getCities =()=>{
  return axios.get(`${apiUrl}/v1/city/get-all`).then((res)=>{return res.data})
}

const getAuditionCities =(city_id)=>{
  return axios.get(`${apiUrl}/v1/auditions/audition-city/${city_id}`).then((res)=>{return res.data})
}

const getCityAllData =()=>{
  return axios.get(`${apiUrl}/v1/city/get-all/city-data`).then((res)=>{return res.data})
}



const createCity = (country_id,city_name,active_status) => {
	return axios.post(`${apiUrl}/v1/city`, {
		country_id:country_id,
    city_name: city_name,
    active_status : active_status
   
})
}
const updateCity= (master_city_id,country_id,city_name,active_status) => {
	return axios.patch(`${apiUrl}/v1/city/${master_city_id}`, {
		country_id:country_id,
	    city_name: city_name,
        active_status: active_status,
  })
}

const deleteCity = (master_city_id) => {
	return axios.delete(`${apiUrl}/v1/city/${master_city_id}`, {})
}

const getCityByid = (master_city_id) => {
	return axios.get(`${apiUrl}/v1/city/${master_city_id}`, {})
}
const createLogs =(payload) =>{
  return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}

export const cityService = {
  getCity,
  createLogs,
  createCity,
  getCityByid,
  updateCity,
  deleteCity,
  getCities,
  getCityAllData,
  getAuditionCities
}