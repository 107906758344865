import axios from 'axios'
import { config } from '../../assets/config/config'


const apiUrl = config.api.url


const createProject = (payload) => {
    return axios.post(`${apiUrl}/v1/recruiter-project`,payload).then(resp => resp.data)
}
const createCharacter = (payload) => {
    return axios.post(`${apiUrl}/v1/recruiter-project-character-library/bulkupdate`,payload).then(resp => resp.data)
}

const getCharacterListById = (id,recruiter_id) => {
    return axios.get(`${apiUrl}/v1/recruiter-project-character-library/getcharacterbyprojects?projectId=${id}&recruiterId=${recruiter_id}`,).then(resp => resp.data)
}

const updateCharacterbyId = (id,payload) => {
    return axios.patch(`${apiUrl}/v1/recruiter-project-character-library/${id}`,payload).then(resp => resp.data)
}

const deleteCharacterbyId = (id) => {
    return axios.delete(`${apiUrl}/v1/recruiter-project-character-library/${id}`).then(resp => resp.data)
}

const getProjectByCharacterId = (id) => {
    return axios.get(`${apiUrl}/v1/recruiter-project-character-library/${id}`).then(resp => resp.data)
}
const getProjectDetailsById = (id) => {
    return axios.get(`${apiUrl}/v1/recruiter-project/${id}`).then(resp =>resp.data)
}
const handleUpdateProject = (id,payload) => {
    return axios.patch(`${apiUrl}/v1/recruiter-project/${id}`, payload).then(resp => resp.data)
}
const GetAllCharacterList = async (id) => {
    return axios.get(`${apiUrl}/v1/recruiter-project-character-library/get-all`).then(resp => resp.data)
  }
  const GetAllProject = async () => {
    return axios.get(`${apiUrl}/v1/recruiter-project/get-all`).then(resp => resp.data)
  }
  const getProjectByRecruiterId = async (id) => {
    return axios.get(`${apiUrl}/v1/recruiter-project/recruiter-based-project/${id}`).then(resp => resp.data)
  }
  const getCharacterByProjectId = async (id) => {
    return axios.get(`${apiUrl}/v1/recruiter-project-character-library/get-Character/${id}`).then(resp => resp.data)
  }
  const getCharacterByProjectIdList= async (id) => {
    return axios.get(`${apiUrl}/v1/recruiter-project-character-library/get-Character/edit-audition/${id}`).then(resp => resp.data)
  }
  const getActiveProjects = async () => {
    return axios.get(`${apiUrl}/v1/recruiter-project/opentalents`).then(resp => resp.data)
  }

  const getAuditionDataByCharacterId = async (id) => {
    return axios.get(`${apiUrl}/v1/recruiter-project-character-library/${id}`).then(resp => resp.data)
  }
export const createProjectService = {
    createProject,
    createCharacter,
    getCharacterListById,
    updateCharacterbyId,
    deleteCharacterbyId,
    getProjectByCharacterId,
    getProjectDetailsById,
    handleUpdateProject,
    GetAllCharacterList,
    GetAllProject,
    getProjectByRecruiterId,
    getCharacterByProjectId,
    getActiveProjects,
    getCharacterByProjectIdList,
    getAuditionDataByCharacterId
}