import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import '../../pages/Notification/Notifications.css'
import { useHistory } from 'react-router-dom';
import { Avatar } from '@windmill/react-ui';


export default function ProfileDropDown({ img, firstName, lastName, handleProfile, handleLogout }) {
 const [anchorEl, setAnchorEl] = React.useState(null);
 const open = Boolean(anchorEl);
 const history = useHistory()
 const handleClick = (event) => {
  document.body.classList.add('modal-open');
  setAnchorEl(event.currentTarget);
 };
 const handleClose = () => {
  document.body.classList.remove('modal-open');
  setAnchorEl(null);

 };

 return (
  <div>
   <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
    {/* <Tooltip title="Account settings"> */}
    <button
     className="profileDropDownButton rounded-full focus:shadow-outline-purple focus:outline-none "
     onClick={handleClick}
     aria-controls={open ? 'account-menu' : undefined}
     aria-haspopup="true"
     aria-expanded={open ? 'true' : undefined}

    >
     <Avatar
      className="align-middle top_user_info"
      src={img}
      alt=""
      aria-hidden="true"
     /> <span className="user_name">{firstName} </span>
    </button>
   </Box>
   <Menu
    anchorEl={anchorEl}
    id="account-menu"
    open={open}
    onClose={handleClose}
    onClick={handleClose}
    PaperProps={{
     elevation: 0,
     sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.5))',
      mt: 1.5,
      '& .MuiAvatar-root': {
       width: 32,
       height: 32,
       ml: -0.5,
       mr: 1,
      },

     },
    }}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    // disableAutoFocusItem={true}
    className="after_signup_section prrofileDropdown"
   >
    <MenuItem>
     <div className='px-4 flex mb-1'>
      <div className="left_profile">
       <img style={{ width: 50, height: 50, borderRadius: 100, objectFit: 'cover' }} src={img} alt="Profile" />
      </div>
      <div className="right_profile_info">
       <h4>{firstName} {lastName}</h4>
       <label>Active</label>
      </div>
     </div>
    </MenuItem>
    <MenuItem onClick={handleProfile}> <p className='profileDropDownItems mx-4'>Profile</p></MenuItem>

    {/* <MenuItem onClick={() => console.log('setting')}> <p className='profileDropDownItems mx-4'>Settings</p></MenuItem> */}

    <MenuItem onClick={handleLogout}><p className='profileDropDownItems mx-4'>Logout</p></MenuItem>

   </Menu>
  </div>
 );
}
