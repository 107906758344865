import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getRecruiterProject = ({page,limit,search,projectStatus,selectRecruiter,status}) => {
      return axios.get(`${apiUrl}/v1/recruiter-project?page=${page}&limit=${limit}&project_title=${search}&status=${projectStatus >=1 ?projectStatus:""}&recruiter=${selectRecruiter ?? ''}&activeProject=${status === true ? 1 :''}`, {})
          .then(response => {
          return response.data
        })
  }

  const deleteProject = (project_id,payload) => {
    return axios.patch(`${apiUrl}/v1/recruiter-project/soft-delete/${project_id}`,payload).then(resp =>resp.data)
  }

  const updateProject = (project_id,payload) => {
    return axios.patch(`${apiUrl}/v1/recruiter-project/${project_id}`,payload).then(resp =>resp.data)
  }

  const getProject = (project_id) => {
    return axios.get(`${apiUrl}/v1/recruiter-project/${project_id}`).then(resp =>resp.data)
  }
  const closeProjectReason = () => {
    return axios.get(`${apiUrl}/v1/report-reason/reportProjectList`).then(resp => resp.data)
}
const projectAbuseReport = (payload) =>{
  return axios.post(`${apiUrl}/v1/project-abuse-report`, payload).then(resp => resp.data)
}

export const recruiterProjectService = {
    getRecruiterProject,
    deleteProject,
    updateProject,
    getProject,
    closeProjectReason,
    projectAbuseReport
  }