import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url


const getCmsByid = (id) => {
	return axios.get(`${apiUrl}/v1/cms/cms-policy/${id}`, {}).then((response) => {
		console.log("---------data--->",response)
		return response.data
	})
}

export const cmsService_terms = {
    getCmsByid
}