import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url


const getLogs = (page,limit) => {
  return axios.get(`${apiUrl}/v1/logs?page=${page}&limit=${limit}`, {})
      .then(response => {
      return response.data
    })
}

const createLogs = (payload) =>{
return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}
const getAllLogs = () =>{
    return axios.get(`${apiUrl}/v1/logs`).then((res)=>{return res.data})
    }

const deleteLogs = (id) =>{
  return axios.delete(`${apiUrl}/v1/logs/${id}`)
}
const searchFilterLogs = (searchData,limit=50) => {
  return axios.get(`${apiUrl}/v1/logs/log-search?page=1&limit=${limit}&message=${searchData}`)
 }
//  /v1/logs/log-search?page=1&limit=50&message=Anandhan
export const LogsService = {
    createLogs,
    getLogs,
    getAllLogs,
    deleteLogs,
    searchFilterLogs
}