import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getAccent = (page,limit,search,status) => {
	return axios.get(`${apiUrl}/v1/accents?page=${page}&limit=${limit}&accent_name=${search}&active_status=${status}`, {})
		.then(response => {
  console.log('-----------------accents server------------------->',response);
	    return response.data
	  })
}

const createAccent = (accent_name,active_status) => {
	return axios.post(`${apiUrl}/v1/accents`, {
         accent_name:accent_name,
        active_status: active_status,
   
})
}
const updateAccent = (master_accent_id,accent_name,active_status) => {
	return axios.patch(`${apiUrl}/v1/accents/${master_accent_id}`, {
    accent_name: accent_name,
    active_status: active_status,
  })
}

const deleteAccent = (master_accent_id) => {
	return axios.delete(`${apiUrl}/v1/accents/${master_accent_id}`, {})
}

const createLogs =(payload) =>{
  return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}
export const accentService = {
  getAccent,
  createLogs,
  createAccent,
  updateAccent,
  deleteAccent,
}