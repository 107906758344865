import axios from 'axios'
import { config } from '../../assets/config/config'
const apiUrl = config.api.url

const getAllRecruiters = () => {
    return axios.get(`${apiUrl}/v1/recruiter/get-all/data`)
      .then(response => {
        return response.data
      })
  }

const addAmount = (data) =>{
    return axios.post(`${apiUrl}/v1/recruiter-balance-history/admin/addpoints`, data)
    .then(response => {
      return response.data
    })
}


const filter_recruiters = (data)=>{
  return axios.get(`${apiUrl}/v1/recruiter-balance-history/recruiter-balance-list?page=${data.page}&limit=${data.limit}&startDate=${data.startDate}&endDate=${data.endDate}&changeType=${data.changeType}&search=${data.search}&activeUsers=${data.activeUsers}`)
  .then(response => {
    return response.data
  })
}

const getRecruiterDetailsById = (userId) => {
  return axios.get(`${apiUrl}/v1/recruiter/get-recruiter-details/${userId}`)
    .then(response => {
      return response.data
    })
}

const updateCreditBalance = (userId,bodyData) => {
  return axios.patch(`${apiUrl}/v1/recruiter/${userId}`,bodyData)
    .then(response => {
      return response.data
    })
}

  

export const creditManagementService = {
    getAllRecruiters,
    addAmount,
    filter_recruiters,
    getRecruiterDetailsById,
    updateCreditBalance
  }