const Joi = require('joi');

const envVarsSchema = Joi.object()
  .keys({
    NODE_ENV: Joi.string().valid('production', 'development', 'demo', 'test').required(),
    REACT_APP_LOCAL_API_URL: Joi.string().allow('').description('backend server url'),
    REACT_APP_DEVELOPMENT_API_URL: Joi.string().allow('').description('backend server url'),
    REACT_APP_LIVE_API_URL: Joi.string().allow('').description('backend server url'),

    REACT_APP_TINYMCE_KEY: Joi.string().description('TinyMCE key'),
    REACT_APP_USERS_PER_PAGE: Joi.number().default(10).description('number of users per page in users table'),
    REACT_APP_ENVIRONMENT: Joi.string().description('APP Environment'),

    REACT_APP_CLIENT_LOCAL_URL: Joi.string().description('Local Client APP URL '),
    REACT_APP_CLIENT_DEVELOPMENT_URL: Joi.string().description('Development Client APP URL'),
    REACT_APP_CLIENT_LIVE_URL: Joi.string().description('live Client APP URL'),


    REACT_APP_ADMIN_LOCAL_URL: Joi.string().description('Local admin APP URL'),
    REACT_APP_ADMIN_DEVELOPMENT_URL: Joi.string().description('development admin APP URL'),
    REACT_APP_ADMIN_LIVE_URL: Joi.string().description('live admin APP URL'),
    
    REACT_APP_BUCKETNAME: Joi.string().description('bucket name'),
    REACT_APP_REGION: Joi.string().description('region'),
    REACT_APP_ACCESSKEY_ID: Joi.string().description('access key id'),
    REACT_APP_SECRETACCESSKEY: Joi.string().description('secret access key'),

  })
  .unknown();

const { value: envVars, error } = envVarsSchema.prefs({ errors: { label: 'key' } }).validate(process.env);

if (error) {
  throw new Error(`Config validation error: ${error.message}`);
}
// demo server 
// export const s3config = {
//   bucketName: 'castingbell-nb',
//   region: 'ap-south-1',
//   accessKeyId: 'AKIAUZCYLRRQXGFPF2ZO',
//   secretAccessKey: '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',
// }


// live server
export const s3config = {
  bucketName:  envVars.REACT_APP_BUCKETNAME,
  region: envVars.REACT_APP_REGION,
  accessKeyId: envVars.REACT_APP_ACCESSKEY_ID,
  secretAccessKey: envVars.REACT_APP_SECRETACCESSKEY,
  AllowedOrigins: ["*"],
}
export const config = {
  api: {
    url: envVars.REACT_APP_ENVIRONMENT === 'production' ? envVars.REACT_APP_LIVE_API_URL : envVars.REACT_APP_ENVIRONMENT === 'development' ?  envVars.REACT_APP_DEVELOPMENT_API_URL : envVars.REACT_APP_LOCAL_API_URL ,

  },
  tinymce: {
    apiKey: 'kf2ams37efxucxax5p7g0uqqvptxzeuf68byj8b2suhkdqzx'
  },
  siteUrl: 'https://dev-client.castingbell.com/jobs/',

  client_url: envVars.REACT_APP_ENVIRONMENT === 'production' ? envVars.REACT_APP_CLIENT_LIVE_URL : envVars.REACT_APP_ENVIRONMENT === 'development' ?  envVars.REACT_APP_CLIENT_DEVELOPMENT_URL : envVars.REACT_APP_CLIENT_LOCAL_URL,

  admin_url: envVars.REACT_APP_ENVIRONMENT === 'production' ? envVars.REACT_APP_ADMIN_LIVE_URL : envVars.REACT_APP_ENVIRONMENT === 'development' ?  envVars.REACT_APP_ADMIN_DEVELOPMENT_URL : envVars.REACT_APP_ADMIN_LOCAL_URL, 
  users: {
    resultsPerPage: envVars.REACT_APP_USERS_PER_PAGE,
  },
  // tinymce: {
  //   apiKey:j4hh0b5907cltay2v6bmdtp2eac1327fskau73390fzi07tb,
  // },
  country: {
    resultsPerPage: envVars.REACT_APP_DEVICE_PER_PAGE,
  },
  city: {
    resultsPerPage: envVars.REACT_APP_DEVICE_PER_PAGE,
  },
  project: {
    resultsPerPage: envVars.REACT_APP_DEVICE_PER_PAGE,
  },
  email_templates: {
    resultsPerPage: envVars.REACT_APP_EMAIL_TEMPLATES_PER_PAGE,
  },
  roles: {
    resultsPerPage: envVars.REACT_APP_DEVICE_PER_PAGE,
  },
};
