import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getTalenter = (page) => {
    //console.log(getCountry);
      return axios.get(`${apiUrl}/v1/talenter`)
          .then(response => {
    //console.log(response);
          return response.data
        })
  }

  const updateTalenter= (id , role_type,first_name, last_name, current_residing_in, date_of_birth, mobile, gender, whatsapp_updates, show_to_recruiters, id_number, id_proof,  isManagedByManager,  manangerEmail,  manangerNumber,  isUpdateManangerEmail,  isUpdateManangerNumber) => {
    console.log("----------updated---------->",id)
      return axios.patch(`${apiUrl}/v1/talenter/${id}`, {
          role_type: role_type,
          first_name: first_name,
          last_name: last_name,
          current_residing_in: current_residing_in,
          date_of_birth: date_of_birth,
          mobile: mobile,
          id_number: id_number,
          gender: gender,
          whatsapp_updates: whatsapp_updates,
          show_to_recruiters: show_to_recruiters,
          id_proof: id_proof,
          isManagedByManager: isManagedByManager,
          manangerEmail: manangerEmail,
          manangerNumber: manangerNumber,
          isUpdateManangerEmail: isUpdateManangerEmail,
          isUpdateManangerNumber: isUpdateManangerNumber,
  
    })
  }
  

const handleOauthUser = (user) => {
  console.log("apiurl",apiUrl)
  return axios.post(`${apiUrl}/v1/talenter`, user) 
}

const updateTalenter_personalData = (user_uid , role_type,first_name, last_name, current_residing_in, date_of_birth, mobile, gender, whatsapp_updates, show_to_recruiters, id_number, id_proof,  isManagedByManager,  manangerEmail,  manangerNumber,  isUpdateManangerEmail,  isUpdateManangerNumber) => {
  console.log("apiurl",apiUrl)
  	return axios.patch(`${apiUrl}/v1/talenter/${user_uid}`, {
        role_type: role_type,
        first_name: first_name,
        last_name: last_name,
        current_residing_in: current_residing_in,
        date_of_birth: date_of_birth,
        mobile: mobile,
        id_number: id_number,
        gender: gender,
        whatsapp_updates: whatsapp_updates,
        show_to_recruiters: show_to_recruiters,
        id_proof: id_proof,
        isManagedByManager: isManagedByManager,
        manangerEmail: manangerEmail,
        manangerNumber: manangerNumber,
        isUpdateManangerEmail: isUpdateManangerEmail,
        isUpdateManangerNumber: isUpdateManangerNumber,

  })
}




const createTalenter_PhysicalDescription = (talenter_id , screen_min_age, screen_max_age, eye_color, color_of_the_skin, natural_hair_color, bust_or_chest, waist,hips,height) => {
	return axios.post(`${apiUrl}/v1/talenter-physical-description`, {
    talenter_id: talenter_id,
    screen_min_age: screen_min_age,
    screen_max_age: screen_max_age,
    eye_color: eye_color,
    color_of_the_skin: color_of_the_skin,
    natural_hair_color: natural_hair_color,
    bust_or_chest: bust_or_chest,
    waist: waist,
    hips: hips,
    height: height
  })
}


const createTalenter_Prefenence = (talenter_id, preferred_role, preferred_projects, preferred_cites, nudity, love_making_scenes) => {
	return axios.post(`${apiUrl}/v1/preference`, {
    talenter_id: talenter_id,
    preferred_role: preferred_role,
    preferred_projects: preferred_projects,
    preferred_cites: preferred_cites,
    nudity: nudity,
    love_making_scenes: love_making_scenes
   
  })
}



const createTalenter_LanguageAndTravel = (talenter_id, nationality, ethnicity, native_city, valid_passport, lived_in_city, countries_visited,language_skills,accent_skills) => {
	return axios.post(`${apiUrl}/v1/language-and-travel`, {
    talenter_id: talenter_id,
    nationality: nationality,
    ethnicity: ethnicity,
    native_city: native_city,
    lived_in_city: lived_in_city,
    valid_passport: valid_passport,
    countries_visited: countries_visited,
    language_skills: language_skills,
    accent_skills: accent_skills,
   
  })
}


const createTalenter_SkillDescription = (talenter_id, education, hobbies_interests, skils, notable_achivements, other_notable, project_name, producation_year, project_description) => {
	return axios.post(`${apiUrl}/v1/skill-and-education`, {
    talenter_id: talenter_id,
    education: education,
    hobbies_interests: hobbies_interests,
    skils: skils,
    notable_achivements: notable_achivements,
    other_notable: other_notable,
    project_name: project_name,
    producation_year: producation_year,
    project_description: project_description,
  })
}
export const talenterService = {
    getTalenter,
    handleOauthUser,
    updateTalenter,
    updateTalenter_personalData,
    createTalenter_PhysicalDescription,
    createTalenter_Prefenence,
    createTalenter_LanguageAndTravel,
    createTalenter_SkillDescription
}