import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getCountry = (page,limit,search,status) => {
  //console.log(getCountry);
	return axios.get(`${apiUrl}/v1/country?page=${page}&limit=${limit}&country_name=${search}&active_status=${status}`, {})
		.then(response => {
  // console.log('-----------------country server------------------->',response);
	    return response.data
	  })
}

const getAllCountry = () => {
  //console.log(getCountry);
	return axios.get(`${apiUrl}/v1/country/get-all`)
		.then(response => {
  console.log('-----------------country service----------------------->',response);
	    return response.data
	  })
}

const createCountry = (country_name,locale_name,active_status) => {
	return axios.post(`${apiUrl}/v1/country`, {
        country_name: country_name,
        locale_name:locale_name,
        active_status : active_status,
   
})
}
const updateCountry = (master_country_id,country_name,locale_name,active_status) => {
 
	return axios.patch(`${apiUrl}/v1/country/${master_country_id}`, {
    country_name: country_name,
    locale_name:locale_name,
    active_status: active_status,
  })
}

const deleteCountry = (master_country_id) => {
	return axios.delete(`${apiUrl}/v1/country/${master_country_id}`, {})
}
const createLogs =(payload) =>{
  return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}

export const countryService = {
  getAllCountry,
  createLogs,
  getCountry,
  createCountry,
  updateCountry,
  deleteCountry,
}