import axios from 'axios'
import { config } from '../assets/config/config'


const apiUrl = config.api.url



const createTalenter_Prefenence = (talent_id, preferred_role, preferred_projects, preferred_cites, nudity, love_making_scenes) => {
  return axios.post(`${apiUrl}/v1/preference`, {
    talent_id: talent_id,
    preferred_role: preferred_role,
    preferred_projects: preferred_projects,
    preferred_cites: preferred_cites,
    nudity: nudity,
    love_making_scenes: love_making_scenes

  })
}

const getPreferenceById = (talent_id) => {
  return axios.get(`${apiUrl}/v1/preference/${talent_id}`, {}).then((response) => {
    return response.data
  })
}

const getRole = () => {
  return axios.get(`${apiUrl}/v1/roles/get-all`).then((res) => {
    return res
  })
}
const getPreferedProject = () => {
  return axios.get(`${apiUrl}/v1/project_type/get-all`).then((res) => {
    return res
  })
}

const getProjectType = () => {
  return axios.get(`${apiUrl}/v1/project-type-audition/get-all`).then((res) => {
    return res
  })
}
const getProjectTypeData = (project_id) => {
  return axios.get(`${apiUrl}/v1/auditions/audition-project-type/${project_id}`).then((res) => {
    return res
  })
}

const getPreferedProjectType = () => {
  return axios.get(`${apiUrl}/v1/project-type-audition/get-all`).then((res) => {
    {return res.data}
  })
}


const getCity = () => {
  return axios.get(`${apiUrl}/v1/city/get-all`).then((res) => {
    return res;
  })
}

const getAllRecuiters = () => {
  //console.log(getRecuiters);
	return axios.get(`${apiUrl}/v1/recruiter/get-all/data`)
		.then(response => {
      console.log(" response.data", response)
	    {return response.data}
     
	  })
}

const getRecuiterData = (recruiter_id) => {
  //console.log(getRecuiters);
	return axios.get(`${apiUrl}/v1/auditions/audition-recruiter/${recruiter_id}`)
		.then(response => {
      console.log(" response.data", response)
	    {return response.data}
     
	  })
}



export const preferenceService = {
  getPreferenceById,
  getAllRecuiters,
  getPreferedProjectType,
  createTalenter_Prefenence,
  getRole,
  getPreferedProject,
  getCity,
  getProjectType,
  getRecuiterData,
  getProjectTypeData


}