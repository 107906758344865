import axios from "axios";
import { config } from "../assets/config/config";

const apiUrl = config.api.url;

const getUsers = (
  page,
  limit,
  search,
  gender,
  status,
  idProof,
  ageMin,
  ageMax,
  photo,
  userId
) => {
  return axios
    .get(
      `${apiUrl}/v1/talenter?page=${page}&limit=${limit}&name=${search}&gender=${gender}&profile_status=${status}&id_proof=${
        idProof !== 5 ? idProof : ""
      }&maxAge=${ageMax}&minAge=${ageMin}&has_photo=${photo}&userId=${userId}`,
      {}
    )
    .then((response) => {
      return response.data;
    });
};

const UpdateTalenter = (id, bodyData) => {
  return axios.patch(`${apiUrl}/v1/talenter/${id}`, bodyData);
};
const UpdateUser = (user_id, bodyData) => {
  return axios.patch(`${apiUrl}/v1/user/${user_id}`, bodyData);
};

const updatePublish = (user_id) => {
  const data = {
    user_id: user_id,
  };
  return axios.patch(`${apiUrl}/v1/talenter/publish`, data);
};

const deletePublish = (user_id) => {
  const data = {
    user_id: user_id,
  };
  return axios.delete(`${apiUrl}/v1/talenter/publish`, { data });
};

const GetTalenterProfileData = (id) => {
  return axios.get(`${apiUrl}/v1/user/${id}`).then((resp) => resp.data);
};
const handleUserData = (userData) => {
  return axios.post(`${apiUrl}/v1/user/talenter`, userData);
};
const handleUpdateTalenterdata = (userId, talenterData) => {
  return axios.patch(
    `${apiUrl}/v1/talenter/handle-talenter/${userId}`,
    talenterData
  );
};

const createLogs = (payload) => {
  return axios.post(`${apiUrl}/v1/logs`, payload).then((res) => {
    return res.data;
  });
};

export const userService = {
  getUsers,
  UpdateTalenter,
  updatePublish,
  deletePublish,
  UpdateUser,
  createLogs,
  GetTalenterProfileData,
  handleUserData,
  handleUpdateTalenterdata,
};
