import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getNationality = (page,limit,search,status) => {
	return axios.get(`${apiUrl}/v1/nationality?page=${page}&limit=${limit}&nationality_name=${search}&active_status=${status}`, {})
		.then(response => {
	    return response.data
	  })
}

const getAllNationality = () => {
	return axios.get(`${apiUrl}/v1/nationality/get-all`)
		.then(response => {
	    return response.data
	  })
}

const createNationality = (nationality_name,active_status) => {
	return axios.post(`${apiUrl}/v1/nationality`, {
        nationality_name: nationality_name,
        active_status : active_status,
   
})
}
const updateNationality = (master_nationality_id,nationality_name,active_status) => {
 
	return axios.patch(`${apiUrl}/v1/nationality/${master_nationality_id}`, {
    nationality_name: nationality_name,
    active_status: active_status,
  })
}

const deleteNationality = (master_nationality_id) => {
	return axios.delete(`${apiUrl}/v1/nationality/${master_nationality_id}`, {})
}

const createLogs =(payload) =>{
	return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
  }

export const nationalityService = {
  getAllNationality,
  getNationality,
  createLogs,
  createNationality,
  updateNationality,
  deleteNationality,
}