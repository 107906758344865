import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getLeads = (page,limit,search) => {
    //console.log(getCountry);
      return axios.get(`${apiUrl}/v1/recruiter_leads?page=${page}&limit=${limit}&name=${search}`, {})
          .then(response => {
    //console.log(response);
          return response.data
        })
  }

const createLeads = (payload) =>{
return axios.post(`${apiUrl}/v1/recruiter_leads`,payload).then((res)=>{return res.data})
}
const getAllLeads = () =>{
    return axios.get(`${apiUrl}/v1/recruiter_leads`).then((res)=>{return res.data})
    }

const deleteLeads = (id) =>{
  return axios.delete(`${apiUrl}/v1/recruiter_leads/${id}`)
}
const createLogs =(payload) =>{
  return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}


export const recruiterLeadService = {
    createLeads,
    createLogs,
    getLeads,
    getAllLeads,
    deleteLeads
  }