import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getCountForAdminManagement = () => {
	return axios.get(`${apiUrl}/v1/admin-management/count`)
		.then(response => {
	    return response.data
	  })
}




export const adminManagementService = {
  getCountForAdminManagement,
}