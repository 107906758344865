import axios from 'axios'
import { config } from '../../assets/config/config'

const apiUrl = config.api.url


const openCloseProjectList =(id) =>{
    return axios.get(`${apiUrl}/v1/recruiter-project/activeprojects/${id}`,).then(resp => resp.data)
}

const getAllProjectMenuList = () => {
    return axios.get(`${apiUrl}/v1/recruiter-project/active-inactive-projects`).then(resp => resp.data)
}

const getDouments = (id) => { 
    return axios.get(`${apiUrl}/v1/documentupload/${id}`,).then(resp => resp.data)   
}

const openProject = (payload) => { 
    return axios.post(`${apiUrl}/v1/recruiter-project/clickopenprojectsbyid`,payload).then(resp => resp.data)
}

const deleteDocument =(id) => {
    return axios.delete(`${apiUrl}/v1/documentupload/deletefiles`, { data: { id: id } }).then((response) => {
    })
}

const closeProject =(id ,payload) =>{
    return axios.patch(`${apiUrl}/v1/recruiter-project/${id}`, payload).then((response) => response.data)
}

const updateProject = (id , payload) =>{
    return axios.patch(`${apiUrl}/v1/recruiter-project/${id}`, payload).then((response) => response)
}

const addReason = (payload) =>{
    return  axios.post(`${apiUrl}/v1/report-reason`, payload).then((resp) => { })
}

const addDocument = (payload) =>{
    return  axios.post(`${apiUrl}/v1/documentupload/createdocument`, payload).then((response) => {})
}

const closeProjectReason = () => {
    return axios.get(`${apiUrl}/v1/report-reason/reportProjectList`).then(resp => resp.data)
}

const abuseReport = (payload) => {
    return axios.post(`${apiUrl}/v1/report-abuse`, payload).then(resp => resp.data)
}

const projectAbuseReport = (payload) =>{
    return axios.post(`${apiUrl}/v1/project-abuse-report`, payload).then(resp => resp.data)
}

const getAllLocation = () =>{
    return axios.get(`${apiUrl}/v1/recruiter-project-schedule-location/get-all`).then(resp => resp.data)
}

const deleteLocation =(id) =>{
    return axios.delete(`${apiUrl}/v1/recruiter-project-schedule-location/${id}`).then((response) => {})
}

const addLocation =(payload) =>{
    return  axios.post(`${apiUrl}/v1/recruiter-project-schedule-location`, payload).then((response) => {})
}

const updateLocation = (id , payload) =>{
    return axios.patch(`${apiUrl}/v1/recruiter-project-schedule-location/${id}`, payload).then((response) =>  response)
}

const getSpecialRequirements = () =>{
    return axios.get(`${apiUrl}/v1/recruiter-project-schedule-special-requirement/get-all`).then(resp => resp.data)
}

const updateSpecialRequirements  = (id , payload) =>{
    return axios.patch(`${apiUrl}/v1/recruiter-project-schedule-special-requirement/${id}`, payload).then((response) =>  response)
}

const deleteSpecialRequirements =(id) =>{
    return axios.delete(`${apiUrl}/v1/recruiter-project-schedule-special-requirement/${id}`).then((response) => {})
}

const addSpecialRequirements =(payload) =>{
    return  axios.post(`${apiUrl}/v1/recruiter-project-schedule-special-requirement`, payload).then((response) => {})
}

const getscheduleAssist =(recruiter_id , project_id , identify_id) =>{
    return axios.get(`${apiUrl}/v1/recruiter-project/recruiter-getting/project-schedule-details?recruiterId=${recruiter_id}&projectId=${project_id}&IdentifyId=${identify_id}`).then(resp => resp.data)
}

const getscheduleAssistProject = (recruiter_id) =>{
    return axios.get(`${apiUrl}/v1/recruiter-project/recruiter-getting/project-schedule-details?recruiterId=${recruiter_id}`).then(resp => resp.data)
}

const getSelectedProject = (project_id) =>{
    return axios.get(`${apiUrl}/v1/recruiter-project-schedule-selected-details/project-details/${project_id}`).then(resp => resp.data)
}

const getProjectLocation = (recruiter_id , project_id) =>{
    return axios.get(`${apiUrl}/v1/recruiter-project-schedule-location/projectId-based/location-details?projectId=${project_id}&recruiterId=${recruiter_id}
    `).then(resp => resp.data)
}

const getRequirementProject = (recruiter_id , project_id) =>{
    return axios.get(`${apiUrl}/v1/recruiter-project-schedule-special-requirement/projectId-based/requirement-details?recruiterId=${recruiter_id}&projectId=${project_id}
    `).then(resp => resp.data)
}

const addSelectCharacterDetails = (payload) =>{
    return  axios.post(`${apiUrl}/v1/recruiter-project-schedule-selected-details`, payload).then((resp) => { })
}

const selectedRemove = (payload) => {
    return  axios.post(`${apiUrl}/v1/recruiter-project-schedule-selected-details/schedule-assist-delete
    `, payload).then((resp) => { })
}

const clearRemove =(payload) =>{
    return  axios.post(`${apiUrl}/v1/recruiter-project-schedule-selected-details/schedule-assist-delete
    `, payload).then((resp) => { })
}

const unavaliableAdd = (payload) => {
    return  axios.post(`${apiUrl}/v1/recruiter-project-schedule-unavailable-details
    `, payload).then((resp) => { })
}

const showUnavaliable = (recruiterid,projectid,identifyid,locationId,requirementId) =>{

     if(locationId == undefined && requirementId == undefined){
        return axios.get(`${apiUrl}/v1/recruiter-project-schedule-unavailable-details/getProject/Unavailable-Details?recruiterId=${recruiterid}&projectId=${projectid}&identifyID=${identifyid}
        `).then(resp => resp.data)
     }
     else if(identifyid == 3 && locationId != undefined){
        return axios.get(`${apiUrl}/v1/recruiter-project-schedule-unavailable-details/getProject/Unavailable-Details?recruiterId=${recruiterid}&projectId=${projectid}&identifyID=${identifyid}&locationId=${locationId}
        `).then(resp => resp.data)
     }
     else if(identifyid == 2 && requirementId != undefined){
        return axios.get(`${apiUrl}/v1/recruiter-project-schedule-unavailable-details/getProject/Unavailable-Details?recruiterId=${recruiterid}&projectId=${projectid}&identifyID=${identifyid}&requirementId=${requirementId}
        `).then(resp => resp.data)
     }
     else{
         return axios.get(`${apiUrl}/v1/recruiter-project-schedule-unavailable-details/getProject/Unavailable-Details?recruiterId=${recruiterid}&projectId=${projectid}&identifyID=${identifyid}&locationId=${locationId}&requirementId
         `).then(resp => resp.data)
     }
    // return  axios.get(`${apiUrl}/v1/recruiter-project-schedule-unavailable-details/getProject/Unavailable-Details?recruiterId=${recruiterid}&projectId=${projectid}&identifyID=${identifyid}
    // `).then((resp) => resp.data)
}

const deleteUnavaliable = (id) =>{
    return axios.delete(`${apiUrl}/v1/recruiter-project-schedule-unavailable-details/${id}`).then((response) => {})
}

const characterInvite = (payload)=>{
    return  axios.post(`${apiUrl}/v1/character-invite-talent
    `, payload).then((resp) => { })
}

const showAvailability = (recruiterId , projectId) =>{
    return axios.get(`${apiUrl}/v1/recruiter-project-schedule-unavailable-details/showing/available-details?recruiterId=${recruiterId}&projectId=${projectId}
    `).then(resp => resp.data)
}

const selectAvailability = (recruiterId , projectId , newDate) =>{
    return axios.get(`${apiUrl}/v1/recruiter-project-schedule-unavailable-details/unAvailableDate-Based/available-unAvailable-list?recruiterId=${recruiterId}&projectId=${projectId}&unAvailableDate=${newDate}
    `).then(resp => resp.data)
}

 const unAvaliableleft =  (recruiterId , projectId , IdentifyId) =>{ 
    return axios.get(`${apiUrl}/v1/recruiter-project/recruiter-get/unAvailable-Date/project-details?recruiterId=${recruiterId}&projectId=${projectId}&IdentifyId=${IdentifyId}
    `).then(resp => resp.data)
 }

 const openAudition = (recruiter_id) =>{
    return axios.get(`${apiUrl}/v1/recruiter-project/admin/open-job-posting/${recruiter_id}
    `).then(resp => resp.data)
 }

 const closeAudition = (recruiter_id) =>{
    return axios.get(`${apiUrl}/v1/recruiter-project/admin/close-job-posting/${recruiter_id}
    `).then(resp => resp.data)
 }



export const openProjectService = {
    getDouments,
    openProject,
    openCloseProjectList,
    deleteDocument,
    closeProject,
    updateProject,
    addReason,
    addDocument,
    closeProjectReason,
    getAllProjectMenuList,
    abuseReport,
    projectAbuseReport,
    getAllLocation,
    deleteLocation,
    addLocation,
    updateLocation,
    getSpecialRequirements,
    updateSpecialRequirements,
    deleteSpecialRequirements,
    addSpecialRequirements,
    getscheduleAssist,
    getscheduleAssistProject ,
    getSelectedProject,
    getProjectLocation,
    getRequirementProject,
    addSelectCharacterDetails,
    selectedRemove,
    clearRemove,
    unavaliableAdd,
    showUnavaliable,
    deleteUnavaliable,
    characterInvite,
    showAvailability,
    selectAvailability,
    unAvaliableleft,
    openAudition,
    closeAudition
}
