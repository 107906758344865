import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url



  const getReportAbuse = (page,limit,search,reason) => {
      return axios.get(`${apiUrl}/v1/report-abuse?page=${page}&limit=${limit}&first_name=${search}&reason=${reason}`, {})
          .then(response => {
          return response.data
        })
  }

const createReportAbuse = (payload) =>{
return axios.post(`${apiUrl}/v1/report-abuse`,payload).then((res)=>{return res.data})
}
const getAllReportAbuse = () =>{
    return axios.get(`${apiUrl}/v1/report-abuse`).then((res)=>{return res.data})
    }

const deleteReportAbuse = (id) =>{
  return axios.delete(`${apiUrl}/v1/report-abuse/${id}`)
}
const getAllReason = () =>{
  return axios.get(`${apiUrl}/v1/report-reason`).then((res)=>{return res.data})
  }

export const reportAbuseService = {
    createReportAbuse,
    getReportAbuse,
    getAllReason,
    getAllReportAbuse,
    deleteReportAbuse,
}