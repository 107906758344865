import axios from 'axios'
import { config } from '../../assets/config/config'
const apiUrl = config.api.url




// const getTemplates = (page, limit, search) => {
//   return axios.get(`${apiUrl}/v1/email-notifications/templates?page=${page}&limit=${limit}&ref_name=${search}`, {})
//     .then(response => {
//       console.log('-----------------Email templates server------------------->', response);
//       return response.data
//     })
// }
const getTemplates = (page, limit, search) => {
  return axios.get(`${apiUrl}/v1/notification-templates?page=${page}&limit=${limit}&ref_name=${search}`, {})
    .then(response => {
      return response.data
    })
}


// const getEmailTemplateByID = (id) => {
//   return axios.get(`${apiUrl}/v1/email-notifications/templates/${id}`, {})
//     .then(response => {
//       return response.data
//     })
// }
const getEmailTemplateByID = (id) => {
  return axios.get(`${apiUrl}/v1/notification-templates/${id}`, {})
    .then(response => {
      return response.data
    })
}

const getAllTemplates = () => {
  return axios.get(`${apiUrl}/v1/email-notifications/templates`)
    .then(response => {
      return response.data
    })
}




const tiggerAll = () => {
 return axios.get(`${apiUrl}/v1/tigger/get-all`)
 .then((res) => { 
  console.log("--------get-all--->",res)
  return res.data })
}



const createTemplate = (ref_name, from_name, from_email, reply_to, subject, content, active_status) => {
  return axios.post(`${apiUrl}/v1/email-notifications/templates`, {
    ref_name: ref_name,
    from_name: from_name,
    from_email: from_email,
    reply_to: reply_to,
    subject: subject,
    content: content,
    active_status: active_status,
  })
}
const CreateNotificationTemplate = (payload) => {
  return axios.post(`${apiUrl}/v1/notification-templates`, payload)
}

// const updateTemplate = (id, ref_name, from_name, from_email, reply_to, subject, content, active_status) => {
//   return axios.patch(`${apiUrl}/v1/email-notifications/templates/${id}`, {
//     ref_name: ref_name,
//     from_name: from_name,
//     from_email: from_email,
//     reply_to: reply_to,
//     subject: subject,
//     content: content,
//     active_status: active_status,
//   })
// }
const updateTemplate = (id, payload) => {
  return axios.patch(`${apiUrl}/v1/notification-templates/${id}`, payload)
}
// const getAllRecuiters = () => {
//   //console.log(getRecuiters);
// 	return axios.get(`${apiUrl}/v1/recruiter/get-all`)
// 		.then(response => {
//   console.log('-----------------Recuiters service----------------------->',response);
// 	    return response.data
// 	  })
// }

const deleteTemplate = (id) => {
  return axios.delete(`${apiUrl}/v1/notification-templates/${id}`)
}
const PushNotificationBulk = (payload) =>{
  return axios.post(`${apiUrl}/v1/notification/bulk-create`,payload)

}

const getTemplatesByReference = (ref) => {
  return axios.get(`${apiUrl}/v1/notification-templates/by-ref/${ref}`, {})
    .then(response => {
      return response.data
    })
}
const createLogs =(payload) =>{
  return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}

export const emailtemplateService = {
  getTemplates,
  getEmailTemplateByID,
  getAllTemplates,
  createLogs,
  // getAllRecuiters,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  CreateNotificationTemplate,
  PushNotificationBulk,
  tiggerAll,
  getTemplatesByReference
}