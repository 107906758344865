import React, { useContext, useState } from 'react'
// import { SidebarContext } from '../context/SidebarContext'
import { Link } from 'react-router-dom';
import Logo from '../assets/img/Logo.svg'
import { useLocation } from 'react-router-dom';



import {
  InstagramIcon,
  FacebookIcon2,
  YouTubeIcon,
  TiwtterIcon2,
  Linkedin
} from '../icons'


function Footer() {
  const location = useLocation()

  if (location.pathname === '/app/view-cms') {
    return <></>
  }
  return (
    <footer className="common_footer_section">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap overflow-hidden sm:-mx-2">

          <div className="w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5">
            <div className="footer_left_item">
              <img src={Logo} alt="CastingBell" />
              <p>© 2021 Casting Bell. All rights reserved</p>
            </div>
          </div>

          <div className="w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5">
            <div className="footer_left_item footer_leftpadding">
              <h2>Product</h2>
              <ul>
                <li><Link to="your-link">How does it work?</Link></li>
                {/* <li><Link  to="your-link">For Talents</Link></li>
    <li><Link  to="your-link">For Recruiters</Link></li>
    <li><Link  to="your-link">Pricing</Link></li>
    <li><Link  to="your-link">Find Image Magicians</Link></li>
    <li><Link  to="your-link">Support</Link></li> */}
              </ul>
            </div>
          </div>

          <div className="w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5">
            <div className="footer_left_item footer_leftpadding">
              <h2>Legal</h2>
              <ul>
              <li><Link to="/view-cms/terms-and-condition">Terms of Use</Link></li>
                <li><Link to="/view-cms/privacy-policy">Privacy Policy</Link></li>
              </ul>
            </div>
          </div>

          <div className="w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5">
            <div className="footer_left_item">
              <h2>Company</h2>
              <ul>
                <li><Link to="your-link">About us</Link></li>
                <li><Link to="your-link">Contact</Link></li>
                {/* <li><Link  to="your-link">Partner with us</Link></li>
    <li><Link  to="your-link">Partner’s login</Link></li> */}
              </ul>
            </div>
          </div>
          <div className="w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5">
            <div className="footer_social_media">
              <h2>Follow us</h2>
              <ul>
                <li><a href="https://www.facebook.com/CastingBell" target="_blank"><FacebookIcon2 aria-hidden="true" /></a></li>
                <li><a href="https://www.youtube.com/channel/UC1H-04kUh-NQK_gehl0Y3wg" target="_blank" ><YouTubeIcon aria-hidden="true" /></a></li>
                <li><a href="https://twitter.com/Castingbell_com" target="_blank"><TiwtterIcon2 aria-hidden="true" /></a></li>
                <li><a href="https://www.instagram.com/castingbellindia" target="_blank">   <InstagramIcon aria-hidden="true" /></a></li>
                <li><a href="https://www.linkedin.com/company/castingbell" target="_blank"><Linkedin aria-hidden="true" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
