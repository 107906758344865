import React, { useContext, useState, useEffect } from "react";
import { SidebarContext } from "../context/SidebarContext";
import { Link } from "react-router-dom";
import {
  SearchIcon,
  MoonIcon,
  SunIcon,
  BellIcon,
  MenuIcon,
  OutlinePersonIcon,
  OutlineCogIcon,
  OutlineLogoutIcon,
} from "../icons";
import Logo from "../assets/img/Logo.svg";
import user_profile from "../assets/img/user_profile.jpg";
import {
  Badge,
  Avatar,
  Input,
  Dropdown,
  DropdownItem,
} from "@windmill/react-ui";
import { AuthContext } from "../context/AuthContext";
import Pusher from "pusher-js";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Popover, Typography } from "@mui/material";
import { notificationService } from "../services";
import ReactHtmlParser from "react-html-parser";
import NotificationDropDown from "./Dropdowns/NotificationDropDown";
import ProfileDropDown from "./Dropdowns/ProfileDropDown";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CometChat } from "@cometchat-pro/chat";

//import Avatar from '@material-ui/core/Avatar';

function Header() {
  const { toggleSidebar } = useContext(SidebarContext);
  const {
    user,
    logout,
    isNewNotificationTiggered,
    setIsNewNotificationTiggered,
    accessToken,
    unreadCount,
    setUnreadCount,
    refreshWebSocket,
  } = useContext(AuthContext);
  const [notificationData, setNotificationData] = useState([]);
  const [detect, setDetect] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userUnread, setUserUnread] = useState("");
  const [isCount, setIsCount] = useState(0);

  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;


  const [isNewNotification, setIsNewNotification] = useState(false);

  function handleNotificationsClick() {
    setIsNotificationsMenuOpen(!isNotificationsMenuOpen);
  }

  function handleProfileClick() {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  }
  const GetNotification = (id) => {
    notificationService.getLastThreeNotificationByid(id).then((resp) => {
      setNotificationData(resp.data);
    });
  };

  useEffect(() => {
    if (user?.user_id) {
      GetNotification(user?.user_id);
      // handleCometLogin(user);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.user_id) {
      let userId = `u${user.user_id}`;

      refreshWebSocket(userId);
    }
  }, []);

  useEffect(() => {
    // Ensure user details exist and path is not "/app/messages"
    if (user && user.user_id && path !== "/app/messages") {
      // Fetch user details
      getUserDetails(user.user_id).then(async (resp) => {
        // Calculate unread count difference
        const unreadCountDifference = unreadCount - resp.data.previousCount;


        const payload = {
          isRecruiterUnlocked: false,
          unreadCount: unreadCountDifference,
        };

        // Update unread count for the user
        await updateUnreadCountUser(user.user_id, payload);

        // Prepare payload with unread count information
      });
    } else {
      if (user && user.user_id) {

        let payload = {
          isRecruiterUnlocked: true,
          unreadCount: 0,
          previousCount: unreadCount,
        };
        updateUnreadCountUser(user.user_id, payload);
      }
    }
  }, [unreadCount, path, isCount]);

  useEffect(() => {
    const pusher = new Pusher("67d45544ef59ad205017", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("notification");
    channel.bind("admin", function (jobData) {
      console.log("job", jobData);
      setIsNewNotification(true);
      GetNotification(user?.user_id);
      notificationService
        .getNotificationCountByuserID(user?.user_id)
        .then((resp) => {
          if (resp.data.notificationCount) {
            setNotificationCount(resp.data.notificationCount);
          } else {
            setNotificationCount(0);
          }
        });
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (isNewNotificationTiggered) {
      setIsNewNotification(true);
    }
  }, [isNewNotificationTiggered]);

  const handleCometLogin = async (user) => {
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .autoEstablishSocketConnection(true)
      .setRegion("in")
      .build();
    await CometChat.init("250876a5032cbe03", appSetting).then(
      () => {
        console.log("Initialization completed successfully");
        // You can now call login function.
      },
      (error) => {
        console.log("Initialization failed with error:", error);
        // Check the reason for error and take appropriate action.
      }
    );
    let loggedUserId = `u${user.user_id}`;

    await CometChat.getLoggedinUser().then(
      (user) => {
        console.log("CometChat - user", user);
        if (!user || user?.uid !== loggedUserId) {
          CometChat.login(
            loggedUserId,
            "88a6055f9e2f79ecebbc63357bb3058f3d23fd3a"
          ).then(
            (user) => {
              console.log("Login Successful:", user);
            },
            (error) => {
              console.log("Login failed with exception:", error);
            }
          );
        }
      },
      (error) => {
        console.log("Some Error Occured", error);
      }
    );
  };

  const HandleMarkasRead = (id) => {
    notificationService.setReadNotificationById(id).finally(() => {
      GetNotification(user?.user_id);
    });
  };

  const updateUnreadCountUser = async (user_id, payload) => {
    await notificationService.updateUser(user_id, payload).then((res) => {
      getUserDetails(user.user_id);
    });
  };

  const getUserDetails = async (user_id) => {
    return await notificationService.getUserData(user_id).then((res) => {
    
      setUserUnread(res.data.isRecruiterUnlocked);
      setIsCount(res.data.unreadCount);
      return res;
    });
  };
  let listenerID = "ADMIN_UNIQUE_LISTENER_ID";

  // let uid = `u${user.user_id}`
  CometChat.addMessageListener(
    listenerID,
    new CometChat.MessageListener({
      onTextMessageReceived: (textMessage) => {
        console.log("Text message received successfully", textMessage);
        if (path !== "/app/messages") {
          if (user && user.user_id !== null) {
            let userId = `u${user.user_id}`;
            refreshWebSocket(userId);
          }
        } else {
          let userId = `u${user.user_id}`;

          refreshWebSocket(userId);
        }
      },
    })
  );

  return (
    <div className="header_top_section">
      <header className="z-40 py-4 bg-white shadow-bottom dark:bg-gray-800 new_header_section">
        <div className="container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300">
          {/* <!-- Mobile hamburger --> */}
          <button
            className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:shadow-outline-purple"
            onClick={toggleSidebar}
            aria-label="Menu"
          >
            <MenuIcon className="w-6 h-6" aria-hidden="true" />
          </button>
          <div className="flex justify-center font-bold text-gray-700">
            <Link to="/">
              <img src={Logo} alt="CastingBell" />
            </Link>
          </div>

          <ul className="flex items-center flex-shrink-0 space-x-6">
            {user && (
              <div className="flex justify-right font-bold text-gray-700">
                <Link to="/app/dashboardadmin">Dashboard</Link>
              </div>
            )}{" "}
            {user && (
              <div className="flex justify-right font-bold text-gray-700">
                <Link to="/app/notifications">Notifications</Link>
              </div>
            )}
            {user && (
              <div className="flex justify-right font-bold text-gray-700 message_TotalCount">
                <li className="message_TotalCount">
                  <Link
                    to="/app/messages"
                    className="headerTags"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Messages{" "}
                    {path !== "/app/messages" &&
                      userUnread !== "" &&
                      userUnread !== true &&
                      (isCount > 0 ? <span>{isCount}</span> : "")}
                  </Link>
                </li>
              </div>
            )}
            {user && (
              <NotificationDropDown
                DropDownData={notificationData}
                isNewMenu={isNewNotification}
                setIsNewMenu={(value) => {
                  setIsNewNotification(value);
                  setIsNewNotificationTiggered(value);
                }}
                HandleMarkasRead={HandleMarkasRead}
                newNotificationCount={notificationCount}
                setNotificationCount={setNotificationCount}
              />
            )}
            {/* <!-- Profile menu --> */}
            {user && (
              <ProfileDropDown
                img={
                  "https://images.unsplash.com/photo-1502378735452-bc7d86632805?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&s=aa3a807e1bbdfd4364d1f449eaa96d82"
                }
                firstName={user?.first_name}
                lastName=""
                handleLogout={logout}
                handleProfile={() => console.log("handleprofile")}
              />
            )}
          </ul>
        </div>
      </header>
      <div className="common_height"></div>
    </div>
  );
}

export default Header;
