import axios from "axios";
import { config } from "../assets/config/config";

const apiUrl = config.api.url;

const getRecuiters = (
  page,
  limit,
  search,
  gender,
  status,
  idProof,
  ageMin,
  ageMax,
  photo,
  userId
) => {
  console.log(photo, "servicephtotocheck");
  return axios
    .get(
      `${apiUrl}/v1/recruiter?page=${page}&limit=${limit}&name=${search}&gender=${gender}&profile_status=${status}&id_proof=${idProof}&maxAge=${ageMax}&minAge=${ageMin}&has_photo=${photo}&userId=${userId}`,
      {}
    )
    .then((response) => {
      return response.data;
    });
};

const getAllRecuiters = () => {
  //console.log(getRecuiters);
  return axios.get(`${apiUrl}/v1/recruiter/get-all`).then((response) => {
    return response.data;
  });
};

const createRecruiter = (userData) => {
  return axios.post(`${apiUrl}/v1/user/recruiter`, userData);
};

const getCity = () => {
  return axios.get(`${apiUrl}/v1/city/get-all`).then((res) => {
    return res;
  });
};

const updateRecuiters = (id, bodyData) => {
  return axios.patch(`${apiUrl}/v1/recruiter/${id}`, bodyData);
};

const deleteRecuiters = (id) => {
  return axios.delete(`${apiUrl}/v1/recruiter/${id}`, {});
};

const getUserByid = (user_id) => {
  console.log("user_id", user_id);
  return axios.get(`${apiUrl}/v1/user/${user_id}`, {}).then((response) => {
    return response.data;
  });
};
const sendOTP = (payload) => {
  return axios
    .post(`${apiUrl}/v1/user/send-whatsapp-otp`, payload)
    .then((res) => res.data);
};

const getOtpNewUser = (payload) => {
  return axios
    .post(`${apiUrl}/v1/user/new-user-otp`, payload)
    .then((res) => res.data);
};

const createLogs = (payload) => {
  return axios.post(`${apiUrl}/v1/logs`, payload).then((res) => res.data);
};

const VerifyOtpNewUser = (num, otp) => {
  return axios
    .get(`${apiUrl}/v1/user/new-user-otp?mobile=${num}&otp=${otp}`)
    .then((res) => res.data);
};

export const recruitersService = {
  getAllRecuiters,
  sendOTP,
  getRecuiters,
  getCity,
  createLogs,
  getUserByid,
  createRecruiter,
  updateRecuiters,
  deleteRecuiters,
  getOtpNewUser,
  VerifyOtpNewUser,
};
