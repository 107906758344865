import React, { lazy } from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import Footer from './components/Footer'
import Header from './components/Header'
import ProtectedRoute from './components/Routes/ProtectedRoute'
import ForwardRoute from './components/Routes/ForwardRoute'
// import FindTalent from './pages/FindTalent/FindTalent'

const Layout = lazy(() => import('./containers/Layout'))
const Auth = lazy(() => import('./containers/Auth'))
const Landing = lazy(() => import('./pages/Landing'))
const EmailVerification = lazy(() => import('./pages/EmailVerification'))
const CMSPolicy = lazy(() => import('./containers/CMSPolicy'))


function App() {
  return (
    <>

      <Router>
   

      <Header/>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <ForwardRoute path="/auth" component={Auth} />
          <Route exact path="/view-cms/:id" component={CMSPolicy} />

          {/* Place new routes over this */}
          {/* <Route exact path={["/find-talents12","/find-talents123","/find-talents1234"]} component={FindTalent} /> */}
          <ProtectedRoute path="/app" component={Layout} />
          <Route exact path="/verify-email" component={EmailVerification} />
          {/* <Route exact path="/" component={Landing} /> */}
          <Redirect from="*" to="/auth" />

          
        </Switch>
        <Footer/>
      </Router>
	
    </>
  )
}

export default App
