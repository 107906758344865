import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getCms = (page,limit,search,status) => {
  //console.log(getCountry);
	return axios.get(`${apiUrl}/v1/cms?page=${page}&limit=${limit}&title=${search}&active_status=${status}`, {})
		.then(response => {
  //console.log(response);
	    return response.data
	  })
}

const createCms = (title,content,meta_keywords,meta_description,cms_url,active_status ) => {
  return axios.post(`${apiUrl}/v1/cms`, {
    title :title,
    content:content,
    meta_description:meta_description,
    meta_keywords:meta_keywords,
    cms_url:cms_url,
    active_status:active_status
})
}
const updateCms = (id,title,content,meta_description,meta_keywords,cms_url,active_status) => {
	return axios.patch(`${apiUrl}/v1/cms/${id}`, {
    title :title,
    content:content,
    meta_description:meta_description,
    meta_keywords:meta_keywords,
    cms_url:cms_url,
    active_status:active_status
  })
}

const deleteCms = (id) => {
	return axios.delete(`${apiUrl}/v1/cms/${id}`, {})
}

const getCmsByid = (id) => {
	return axios.get(`${apiUrl}/v1/cms/${id}`, {}).then((response) => {
		return response.data
	})
}

const createLogs =(payload) =>{
  return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}


export const cmsService = {
  createCms,
  createLogs,
  getCms,
  updateCms,
  deleteCms,
  getCmsByid
}

