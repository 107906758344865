import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getEthnicities = (page,limit,search,status) => {
	return axios.get(`${apiUrl}/v1/ethnicities?page=${page}&limit=${limit}&ethnicity_name=${search}&active_status=${status}`, {})
		.then(response => {
  console.log('-----------------ethnicities server------------------->',response);
	    return response.data
	  })
}

const createEthnicities = (ethnicity_name,active_status) => {
	return axios.post(`${apiUrl}/v1/ethnicities`, {
         ethnicity_name:ethnicity_name,
        active_status: active_status,
   
})
}
const updateEthnicities = (master_ethnicity_id,ethnicity_name,active_status) => {
	return axios.patch(`${apiUrl}/v1/ethnicities/${master_ethnicity_id}`, {
    ethnicity_name: ethnicity_name,
    active_status: active_status,
  })
}

const deleteEthnicities = (master_ethnicity_id) => {
	return axios.delete(`${apiUrl}/v1/ethnicities/${master_ethnicity_id}`, {})
}
const createLogs =(payload) =>{
  return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}

export const ethnicitiesService = {
  getEthnicities,
  createLogs,
  createEthnicities,
  updateEthnicities,
  deleteEthnicities,
}