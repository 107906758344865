import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getProject = (page,limit,search,status) => {
  //console.log(getCountry);
	return axios.get(`${apiUrl}/v1/project_type?page=${page}&limit=${limit}&projectname=${search}&active_status=${status}`, {})
		.then(response => {
  //console.log(response);
	    return response.data
	  })
}

const createProject = (projectname,active_status) => {
	return axios.post(`${apiUrl}/v1/project_type`, {
        projectname: projectname,
        active_status : active_status,
   
})
}



const updateProject = (id,projectname,active_status) => {
  //console.log('------------->',id,projectname,active_status)

	return axios.patch(`${apiUrl}/v1/project_type/${id}`, {
    projectname: projectname,
    active_status: active_status,
  })
}

const deleteProject = (id) => {
	return axios.delete(`${apiUrl}/v1/project_type/${id}`, {})
}

const createLogs =(payload) =>{
  return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}

export const projectService = {
  getProject,
  createLogs,
  createProject,
  updateProject,
  deleteProject
}