import axios from 'axios'
import { config } from '../assets/config/config' 

const apiUrl = config.api.url

const imageUpload = (data) => {
	return axios.post(`${apiUrl}/v1/image-upload`,data)
}

export const imageUploadService = {
imageUpload
}