import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getCountForMasterManagement = () => {
	return axios.get(`${apiUrl}/v1/master-management/count`)
		.then(response => {
	    return response.data
	  })
}




export const masterManagementService = {
	getCountForMasterManagement,
}