import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getLanguage = (page,limit,search,status) => {
  //console.log(getCountry);
	return axios.get(`${apiUrl}/v1/language?page=${page}&limit=${limit}&language_name=${search}&active_status=${status}`, {})
		.then(response => {
  //console.log(response);
	    return response.data
	  })
}

const createLanguage= (language_name,active_status) => {
	return axios.post(`${apiUrl}/v1/language`, {
    language_name: language_name,
        active_status : active_status,
   
})
}



const updateLanguage = (master_language_id,language_name,active_status) => {
//console.log('------------->',language_id,skillsname,active_status)
	return axios.patch(`${apiUrl}/v1/language/${master_language_id}`, {
    language_name: language_name,
    active_status: active_status,
  })
}

const deleteLanguage = (master_language_id) => {
	return axios.delete(`${apiUrl}/v1/language/${master_language_id}`, {})
}

const createLogs =(payload) =>{
  return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}

export const languageService = {
  getLanguage,
  createLanguage,
  createLogs,
  updateLanguage,
  deleteLanguage,
}