import React, { useState, useMemo, useEffect, useCallback } from 'react'
import moment from 'moment'
import axios from 'axios'
import { config } from '../assets/config/config'
import ThemedSuspense from '../components/ThemedSuspense'
import { notificationService } from '../services'
import { CometChat } from "@cometchat-pro/chat";
import io from "socket.io-client";


const apiUrl = config.api.url

// create context
export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [isLoaded, setLoaded] = useState(false)
  const [user, setUser] = useState([null])
  const [accessToken, setAccessToken] = useState(null)
  const [dependencyData, setDependency] = useState(null)
  const [userId,setUserId] = useState(null)
  const [isNewNotificationTiggered, setIsNewNotificationTiggered] = useState(false)
  const [unreadCount,setUnreadCount] = useState(0)
  console.log("apiUrl",apiUrl)
  console.log("client_url",config.client_url)
  console.log("admin_url",config.admin_url)

  useEffect (()=>{
   setUserId(user)
  },[user])

  const GetSignUpDependency = async () => {
    return axios.get(`${apiUrl}/v1/get-all-masterData/admin`).then((res) => {
      if (res) {
        setDependency(res.data)
      }
    })
  }

  const refreshTokens = useCallback(
    () => {
      return axios.post(`${apiUrl}/v1/auth/admin-refresh-tokens`, {})
        .then(response => {
          if(response.data.user.user_type == 3){
          setAccessToken(response.data.token)
          setUser(response.data.user)
          GetSignUpDependency()}else{
            // logout
            setUser(null)
          setAccessToken(null)
          }
          return response
        })
        .catch(error => {
          setUser(null)
          setAccessToken(null)
          return error
        })
    },
    []
  )
  const refreshWebSocket = useCallback((user_id) => {
    let socket;
    socket = io(apiUrl);
    socket.on("join", ({ payload }, callBack) => {
      if (payload) {
        return axios
          .get(`${apiUrl}/v1/comet-chat/unread-count/${user_id}`)
          .then((response) => {
            if (response.data) {
              setUnreadCount(response.data.totalUnreadCount);
            } else {
              setUnreadCount(0);
            }
          })
          .catch((error) => {
            setUnreadCount(0);

            return error;
          });
      }
    });
  }, []);

  const startSilentRefresh = useCallback(
    () => {
      if (accessToken) {
        const tokenExpires = moment(accessToken.expires)
        const tokenMaxAge = tokenExpires.diff(moment().add(1, 'minutes'))
        setTimeout(() => {
          refreshTokens()
        }, tokenMaxAge)
      }
    },
    [accessToken, refreshTokens]
  )

  const syncLogout = (event) => {
    if (event.key === 'logout') {
      setAccessToken(null)
      setUser(null)
    }
  }
  const checkNewNotification = (id) => {
    notificationService.getLastThreeNotificationByid(id).then(resp => {
      if(resp?.data?.filter(each => each?.status === false)?.length){
        setIsNewNotificationTiggered(true)
      }
    })
  }


  // useEffect(() => {
  //   console.log("user?.user_type", user?.user_type)
  //   if (user?.user_type !== 3) {
  //     setAccessToken(null)
  //     setUser(null)
  //   }
  // }, [user])

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(
      config => {
        config.withCredentials = true
        config.credentials = 'include'
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken.token}`
        }
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    return () => {
      axios.interceptors.request.eject(interceptorId);
    }
  }, [accessToken])




  useEffect(() => {
    refreshTokens()
      .then(response => {
        setLoaded(true)
      })
  }, [refreshTokens])

  useEffect(() => {
    startSilentRefresh()
  }, [accessToken, startSilentRefresh])

  useEffect(() => {
    window.addEventListener('storage', syncLogout)
    return function cleanup() {
      window.removeEventListener('storage', syncLogout)
    }
  }, [])


  const value = useMemo(
    () => {
      const register = (username, email, password) => {
        return axios.post(`${apiUrl}/v1/auth/register`, {
          name: username,
          email: email,
          password: password
        })
          .then(response => {
            setAccessToken(response.data.token)
            setUser(response.data.user)
            startSilentRefresh()
          })
      }


      const login = (email, password) => {
        return axios.post(`${apiUrl}/v1/auth/login`, {
          email: email,
          password: password
        })
          .then(response => {
            console.log("login", response)
            setAccessToken(response.data.token)
            setUser(response.data.user)
            setUserId(response.data.user)
            GetSignUpDependency()
            startSilentRefresh()
            checkNewNotification(response.data.user?.user_id)
          })
      }

      const githubAuth = () => {
        window.location.href = `${apiUrl}/v1/auth/github`;
      }

      const logout = () => {
        setAccessToken(null)
        setUser(null)
        let listenerID = "ADMIN_UNIQUE_LISTENER_ID";
        CometChat.removeMessageListener(listenerID);
        return axios.post(`${apiUrl}/v1/auth/admin-logout`, {})
          .then(response => {
            window.localStorage.setItem('logout', moment())
            handleLogout()
          })
          .catch(err => { })
      }

      const handleLogout = () => {
        CometChat.logout().then(
          () => {
            console.log('Admin user logged out successfully');
            // Perform any additional cleanup or redirect logic
          },
          (error) => {
            console.log('Admin logout failed with error:', error);
          }
        );
      };

      const forgotPassword = (email) => {
        return axios.post(`${apiUrl}/v1/auth/forgot-password`, {
          email: email
        })
      }

      const resetPassword = (password, resetToken) => {
        return axios.post(`${apiUrl}/v1/auth/reset-password?token=${resetToken}`, {
          password: password
        })
      }

      const verifyEmail = (emailVerificationToken) => {
        return axios.post(`${apiUrl}/v1/auth/verify-email?token=${emailVerificationToken}`, {})
      }




      return ({
        user,
        userId,
        setUser,
        register,
        login,
        githubAuth,
        logout,
        forgotPassword,
        resetPassword,
        verifyEmail,
        GetSignUpDependency,
        dependencyData,
        setIsNewNotificationTiggered,
        isNewNotificationTiggered,
        unreadCount,
        setUnreadCount,
        refreshWebSocket
      })
    },
    [unreadCount,setUnreadCount,user,userId, startSilentRefresh, dependencyData,isNewNotificationTiggered]
  )

  if (!isLoaded) {
    return <ThemedSuspense />
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
