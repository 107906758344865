import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getSkills = (page,limit,search,status) => {
  //console.log(getCountry);
	return axios.get(`${apiUrl}/v1/skills?page=${page}&limit=${limit}&skill_name=${search}&active_status=${status}`, {})
		.then(response => {
  console.log("----------------skill get-------------------->",response);
	    return response.data
	  })
}

const createSkills = (skill_name,active_status) => {
	return axios.post(`${apiUrl}/v1/skills`, {
    skill_name: skill_name,
        active_status : active_status,
   
})
}



const updateSkills = (id,skill_name,active_status) => {
//console.log('------------->',id,skillsname,active_status)
	return axios.patch(`${apiUrl}/v1/skills/${id}`, {
    skill_name: skill_name,
    active_status: active_status,
  })
}

const deleteSkills = (id) => {
	return axios.delete(`${apiUrl}/v1/skills/${id}`, {})
}

const createLogs =(payload) =>{
  return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}

export const skillsService = {
  getSkills,
  createLogs,
  createSkills,
  updateSkills,
  deleteSkills,
}