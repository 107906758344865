import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url

const getHobbies = (page,limit,search,status) => {
  //console.log(getCountry);
	return axios.get(`${apiUrl}/v1/hobbies?page=${page}&limit=${limit}&hobby_name=${search}&active_status=${status}`, {})
		.then(response => {
  //console.log(response);
	    return response.data
	  })
}

const createHobbies = (hobby_name,active_status) => {
	return axios.post(`${apiUrl}/v1/hobbies`, {
        hobby_name: hobby_name,
        active_status : active_status,
   
})
}



const updateHobbies = (master_hobby_id,hobby_name,active_status) => {
//console.log('------------->',master_hobby_id,hobby_name,active_status)
	return axios.patch(`${apiUrl}/v1/hobbies/${master_hobby_id}`, {
    hobby_name: hobby_name,
    active_status: active_status,
  })
}

const deleteHobbies = (master_hobby_id) => {
	return axios.delete(`${apiUrl}/v1/hobbies/${master_hobby_id}`)
}

const createLogs =(payload) =>{
  return axios.post(`${apiUrl}/v1/logs`,payload).then((res)=>{return res.data})
}

export const hobbiesService = {
  getHobbies,
  createLogs,
  createHobbies,
  updateHobbies,
  deleteHobbies,
}