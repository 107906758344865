import axios from "axios";
import { config } from "../assets/config/config";

const apiUrl = config.api.url;

const updateCometUser = async (userId, user) => {
  return axios
    .put(`${apiUrl}/v1/comet-chat/update-user-email/unread-message/${userId}`, {
      metadata: {
        "@private": {
          email: `${user.email}`,
          contactNumber: `${(user.mobile && user.mobile) || ""}`,
        },
      },
    })
    .then((resp) => {
      return resp.data;
    });
};

export const cometChatService = {
  updateCometUser,
};
