import React from 'react'

import Lottie from 'react-lottie-player'
import loaderJson from '../assets/img/Loader.json'

function ThemedSuspense() {
  return (
    <div className="w-full h-screen p-6 text-lg font-medium bg-white text-gray-600 dark:text-gray-400 dark:bg-gray-900 flex justify-center items-center">
      <Lottie
        loop
        animationData={loaderJson}
        play
        style={{maxWidth: "600px", maxHeight: "600px" }}
      />
      {/* <div className='w-32'>
        <img src={loader} alt='loader' />

      </div> */}
    </div>
  )
}

export default ThemedSuspense
